.partner-banner {
  padding-top: 80px;
  height: 300px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding-top: 40px;
    height: 200px;
  }
}

.partner-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Set a fixed height for each slide */
  overflow: hidden; /* Prevent content from overflowing */
}

.partner-slide img {
  max-height: 100%; /* Ensure the image fits within the container's height */
  max-width: 100%; /* Ensure the image does not overflow horizontally */
  object-fit: contain; /* Maintain aspect ratio without cropping */
}
