.two_grid {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  justify-items: end;
  column-gap: 2rem;
}

.two_grids_right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
  column-gap: 4rem;
}

.two_grids_left {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
  column-gap: 4rem;
  direction: rtl;
}

/*60%, 40% grid*/

.grid_mtwo {
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  column-gap: 3.64rem;
}

.grid_rtwo {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  column-gap: 5rem;
}

/* Card Grid */
.m_card {
  display: grid;
  grid-template: 'img title' min-content 'img content' 1fr / auto 3fr;
  grid-column-gap: 1.5em;
}

.m_card-img {
  grid-area: img;
  max-width: 100%;
}

.m_card-title {
  grid-area: title;
  margin: 0;
  font-weight: 300;
}

.m_card-content {
  grid-area: content;
}

/* Two Column Grid */
.two_grido {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: baseline;
  column-gap: 4rem;
}

/* Footer Grid */
.inr-footrer-grid {
  display: grid;
  grid-template-columns: 0.7fr 1fr 1fr;
  align-items: end;
  padding: 1.5rem 0;
}

/* Services Grid */
.ser-blk .hm-grid-thr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  margin-top: 4rem;
}

/* Logo Carousel Grid */
.main-caro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
  gap: 3rem;
  padding: 0 12rem;
  flex-direction: row;
}

.main-caro > div {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.main-caro > div > img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

/* Responsive Grid */
@media (min-width: 1600px) {
  .main-caro {
    padding: 0 12rem;
    flex-wrap: nowrap;
  }
  .main-caro > div {
    width: 16.66%;
  }
  .main-caro > div > img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
}

@media (min-width: 1400px) {
  .main-caro {
    padding: 0 6rem;
    flex-wrap: nowrap;
  }
  .main-caro > div {
    width: 16.66%;
  }
  .main-caro > div > img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
}

@media (max-width: 1400px) and (min-width: 850px) {
  .main-caro {
    padding: 0 6rem;
    flex-wrap: wrap;
  }
}

@media (max-width: 850px) {
  .main-caro {
    padding: 0 3rem;
    flex-wrap: wrap;
  }
  .main-caro > div {
    width: 100%;
  }
}
