.cardo {
    display: grid;
    grid-template: 'img title' min-content 'img content' auto/ auto 3fr;
    margin-bottom: 1.5em;
    grid-column-gap: 0.51rem;
}

.card-imgo {
    grid-area: img;
    max-width: 100%;
}

.card-titleo {
    grid-area: title;
    margin: 0;
    font-weight: 300;
}

.card-contento {
    grid-area: content;
    margin-top: -20px;
}

.main-dp-h-cr {
    display: flex;
    align-items: baseline;
    margin-top: 0.51rem;
}


.owl-carousel .item {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 1.2rem;
    border-radius: 10px;
    background: #fff;
}

.sec-owl-caro .owl-carousel .item {
    box-shadow: none;
}

.slick-slide div {
   margin: 0 .5rem;
}

.bloc-cd img {
    left: 0;
    position: absolute;
    top: 75px;
}

.carousel-indicators [data-bs-target] {
    background-color: #ff6600 !important;
}

.slick-slide div {
    margin: 0 10px;
}

.slick-track {
    height: 600px !important;
    margin-left: -10px !important;
}

.slick-dots li button:before {
    background-color: #ff6600 !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
    content: '';
}
.slick-dots .slick-active button:before {
    background-color: #ff6600 !important;
    width: 25px !important;
    height: 9px !important;
    border-radius: 29%;
    content: '';
    margin-top: 2px;
    margin-left: -6px;
}
