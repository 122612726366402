.appointment-form-wrapper {
  height: 600px;

  @media screen and (max-width: 768px) {
    height: auto;
  }
}
.appointment-form {
  flex: 1;
  justify-content: stretch;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.appointment-form .form_inp {
  width: 100%;
  box-shadow: none;
}

.appointment-form h3 {
  color: var(--primary-color);
  font-size: 2.5rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.appointment-bg {
  flex: 1;
  justify-content: stretch;
  background-image: url("../../Assets/images/section-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  width: 100%;
  height: 100%;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-in-out forwards;
}

