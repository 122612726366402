.accordion.accordion-modern-status > .card .card-header a.collapsed:after {
  content: '+';
  transform: none;
}

.accordion.accordion-modern-status > .card .card-header a:after {
  content: '-';
  transform: none;
  top: 28%;
}
.accordion-header img {
  width: auto;
  margin-right: 0.5rem;
}
.accordion-button::after {
  /* background-image: url('./Assets/images/add.png') !important; */
  background-size: cover;
}

/*
.accordion-button:not(.collapsed):after {
  background-image: url(./Assets/images/add.png) !important;
  height: 24px;
  background-size: cover;
}
*/

.accordion {
  width: 1000px;
  margin: 0 auto;
}

.accordion-button {
  display: flex;
  align-items: center; /* Vertically align the icon and text */
  justify-content: flex-start; /* Keep the icon and text aligned to the left */
  padding: 1rem; /* Adjust padding as needed */
}

/* Default state */
.accordion-button::before {
  content: '';
  background: url(../images/faq-3.png) no-repeat center center;
  background-size: contain;
  width: 41px;
  height: 41px;
  flex-shrink: 0;
  margin-right: 0.81rem;
}

/* Expanded state */
.accordion-button:not(.collapsed)::before {
  content: '';
  background: url(../images/ques.png) no-repeat center center;
  background-size: contain;
  width: 41px;
  height: 41px;
  margin-right: 0.81rem;
}

.accordion-item {
  background: transparent !important;
  border: none;
}

.accordion-body {
  padding: 1rem 1.5rem !important;
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #ffffff;
  box-shadow: none !important;
}

.accordion-item button {
  background: #fff;
  color: #000;
  /* border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important; */
  margin-bottom: 1rem;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.accordion-collapse {
  margin-top: -2rem;
  background: #fff;
  margin-bottom: 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 1rem;
}

.accordion-button {
  padding: 1rem !important;
  border-radius: 1rem !important;
}

.accordion-button .collapsed {
  border-radius: 1rem !important;
}

.accordion-button:focus {
  border-color: inherit;
  outline: 0;
  box-shadow: none;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.accordion .card {
  margin-top: 5px;
}

.accordion .card:first-child {
  margin-top: 0;
}

.accordion .card:first-of-type,
.accordion .card:not(:first-of-type):not(:last-of-type),
.accordion .card:last-of-type {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0.25rem;
}

.accordion .card-title {
  font-size: 16px;
}

.accordion .card-header {
  padding: 0;
  border-radius: 0.25rem;
  border-bottom: 0;
  margin: -1px;
}

.accordion .card-header a {
  cursor: pointer;
  display: block;
  padding: 10px 20px;
}

.accordion .card-header a:hover,
.accordion .card-header a:focus {
  text-decoration: none;
}

.accordion .card-header a [class^='icon-'] {
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.accordion .card-header a [class*='fa-'],
.accordion .card-header a .icons {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.accordion .card-header a .icons {
  top: 1px;
}

.accordion.accordion-sm .card-header a {
  padding: 5px 15px;
  font-size: 0.85em;
}

.accordion.accordion-sm .card-body {
  font-size: 0.9em;
}

.accordion.accordion-lg .card-header a {
  padding: 15px 15px;
  font-size: 1em;
  font-weight: 600;
}

.accordion.without-bg .card-default {
  background: transparent !important;
}

.accordion.without-bg .card-default > .card-header {
  background: transparent !important;
}

.accordion.without-bg .card-body {
  padding-top: 0;
}

.accordion.without-borders .card {
  border: 0 !important;
  box-shadow: none;
  border-radius: 0;
}

.accordion.without-borders .card-header a {
  padding-left: 0;
  padding-right: 0;
}

.accordion.without-borders .card-body {
  padding: 0;
}

.accordion.accordion-modern .card:first-of-type,
.accordion.accordion-modern .card:not(:first-of-type):not(:last-of-type),
.accordion.accordion-modern .card:last-of-type {
  border: 4px solid #f7f7f7;
}

.accordion.accordion-modern .card .card-header {
  background-color: #f7f7f7;
  border-radius: 0;
}

.accordion.accordion-modern-2 > .card {
  border: 1px solid transparent !important;
  transition: ease border-color 300ms;
}

.accordion.accordion-modern-2 > .card .card-header a {
  position: relative;
  padding: 23px 30px;
  background-color: #f4f4f4;
  transition: ease background-color 300ms, ease color 300ms;
  border-radius: 4px;
}

.accordion.accordion-modern-2 > .card .card-header a:after {
  content: '+';
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: inherit;
  font-weight: inherit;
  transform: translate3d(0, -50%, 0) rotate(45deg);
  transform-origin: 50% 50%;
  transition: ease transform 300ms;
}

.accordion.accordion-modern-2 > .card .card-header a.collapsed:after {
  transform: translate3d(0, -50%, 0) rotate(0deg);
}

.accordion.accordion-modern-2 > .card .card-header + .collapse.show,
.accordion.accordion-modern-2 > .card .card-header + .collapsing {
  border: 1px solid transparent;
  border-top: 0;
}

.accordion.accordion-modern-2 > .card + .card {
  margin-top: 17px;
}

.accordion.accordion-modern-status > .card {
  border: 1px solid transparent !important;
  transition: ease border-color 300ms;
}

.accordion.accordion-modern-status > .card .card-header a {
  position: relative;
  padding: 23px 30px;
  background-color: #f4f4f4;
  transition: ease background-color 300ms, ease color 300ms;
}

.accordion.accordion-modern-status > .card .card-header a:after {
  content: '+';
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: inherit;
  font-weight: inherit;
  transform: translate3d(0, -50%, 0) rotate(45deg);
  transform-origin: 50% 50%;
  transition: ease transform 300ms;
}

.accordion.accordion-modern-status > .card .card-header a.collapsed:after {
  transform: translate3d(0, -50%, 0) rotate(0deg);
}

.accordion.accordion-modern-status > .card .card-header + .collapse.show,
.accordion.accordion-modern-status > .card .card-header + .collapsing {
  border: 0;
}

.accordion.accordion-modern-status > .card + .card {
  margin-top: 17px;
}

.accordion.accordion-modern-status.accordion-modern-status-borders > .card {
  border: 1px solid rgba(0, 0, 0, 0.085) !important;
}

.accordion.accordion-modern-status.accordion-modern-status-borders > .card .card-header {
  background: transparent !important;
}

.accordion.accordion-modern-status.accordion-modern-status-borders > .card .card-header a {
  background: transparent !important;
}

.accordion.accordion-modern-status.accordion-modern-status-arrow > .card .card-header a:after {
  transform: translate3d(0, -50%, 0) rotate(180deg);
  font-family: 'Font Awesome 6 Free';
  content: '\f107';
  font-weight: 900;
}

.accordion.accordion-modern-status.accordion-modern-status-arrow
> .card
.card-header
a.collapsed:after {
  transform: translate3d(0, -50%, 0) rotate(0deg);
}

.accordion.accordion-modern-grey-scale-1 .card:first-of-type,
.accordion.accordion-modern-grey-scale-1 .card:not(:first-of-type):not(:last-of-type),
.accordion.accordion-modern-grey-scale-1 .card:last-of-type {
  border-color: #ededed;
}

.accordion.accordion-modern-grey-scale-1 .card .card-header {
  background-color: #ededed;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: var(--primary-color) !important;
}

.navbar-nav .nav-item .nav-link {
  font-size: 14px;
  font-family: 'Metropolis Semi';
  text-transform: uppercase;
}
.accordion .card {
  margin-top: 5px;
}

.accordion .card:first-child {
  margin-top: 0;
}

.accordion .card:first-of-type,
.accordion .card:not(:first-of-type):not(:last-of-type),
.accordion .card:last-of-type {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0.25rem;
}

.accordion .card-title {
  font-size: 16px;
}

.accordion .card-header {
  padding: 0;
  border-radius: 0.25rem;
  border-bottom: 0;
  margin: -1px;
}

.accordion .card-header a {
  cursor: pointer;
  display: block;
  padding: 10px 20px;
}

.accordion .card-header a:hover,
.accordion .card-header a:focus {
  text-decoration: none;
}

.accordion .card-header a [class^='icon-'] {
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.accordion .card-header a [class*='fa-'],
.accordion .card-header a .icons {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.accordion .card-header a .icons {
  top: 1px;
}

.accordion.accordion-sm .card-header a {
  padding: 5px 15px;
  font-size: 0.85em;
}

.accordion.accordion-sm .card-body {
  font-size: 0.9em;
}

.accordion.accordion-lg .card-header a {
  padding: 15px 15px;
  font-size: 1em;
  font-weight: 600;
}

.accordion.without-bg .card-default {
  background: transparent !important;
}

.accordion.without-bg .card-default > .card-header {
  background: transparent !important;
}

.accordion.without-bg .card-body {
  padding-top: 0;
}

.accordion.without-borders .card {
  border: 0 !important;
  box-shadow: none;
  border-radius: 0;
}

.accordion.without-borders .card-header a {
  padding-left: 0;
  padding-right: 0;
}

.accordion.without-borders .card-body {
  padding: 0;
}

.accordion.accordion-modern .card:first-of-type,
.accordion.accordion-modern .card:not(:first-of-type):not(:last-of-type),
.accordion.accordion-modern .card:last-of-type {
  border: 4px solid #f7f7f7;
}

.accordion.accordion-modern .card .card-header {
  background-color: #f7f7f7;
  border-radius: 0;
}

.accordion.accordion-modern-2 > .card {
  border: 1px solid transparent !important;
  transition: ease border-color 300ms;
}

.accordion.accordion-modern-2 > .card .card-header a {
  position: relative;
  padding: 23px 30px;
  background-color: #f4f4f4;
  transition: ease background-color 300ms, ease color 300ms;
  border-radius: 4px;
}

.accordion.accordion-modern-2 > .card .card-header a:after {
  content: '+';
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: inherit;
  font-weight: inherit;
  transform: translate3d(0, -50%, 0) rotate(45deg);
  transform-origin: 50% 50%;
  transition: ease transform 300ms;
}

.accordion.accordion-modern-2 > .card .card-header a.collapsed:after {
  transform: translate3d(0, -50%, 0) rotate(0deg);
}

.accordion.accordion-modern-2 > .card .card-header + .collapse.show,
.accordion.accordion-modern-2 > .card .card-header + .collapsing {
  border: 1px solid transparent;
  border-top: 0;
}

.accordion.accordion-modern-2 > .card + .card {
  margin-top: 17px;
}

.accordion.accordion-modern-status > .card {
  border: 1px solid transparent !important;
  transition: ease border-color 300ms;
}

.accordion.accordion-modern-status > .card .card-header a {
  position: relative;
  padding: 23px 30px;
  background-color: #f4f4f4;
  transition: ease background-color 300ms, ease color 300ms;
}

.accordion.accordion-modern-status > .card .card-header a:after {
  content: '+';
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: inherit;
  font-weight: inherit;
  transform: translate3d(0, -50%, 0) rotate(45deg);
  transform-origin: 50% 50%;
  transition: ease transform 300ms;
}

.accordion.accordion-modern-status > .card .card-header a.collapsed:after {
  transform: translate3d(0, -50%, 0) rotate(0deg);
}

.accordion.accordion-modern-status > .card .card-header + .collapse.show,
.accordion.accordion-modern-status > .card .card-header + .collapsing {
  border: 0;
}

.accordion.accordion-modern-status > .card + .card {
  margin-top: 17px;
}

.accordion.accordion-modern-status.accordion-modern-status-borders > .card {
  border: 1px solid rgba(0, 0, 0, 0.085) !important;
}

.accordion.accordion-modern-status.accordion-modern-status-borders > .card .card-header {
  background: transparent !important;
}

.accordion.accordion-modern-status.accordion-modern-status-borders > .card .card-header a {
  background: transparent !important;
}

.accordion.accordion-modern-status.accordion-modern-status-arrow > .card .card-header a:after {
  transform: translate3d(0, -50%, 0) rotate(180deg);
  font-family: 'Font Awesome 6 Free';
  content: '\f107';
  font-weight: 900;
}

.accordion.accordion-modern-status.accordion-modern-status-arrow
> .card
.card-header
a.collapsed:after {
  transform: translate3d(0, -50%, 0) rotate(0deg);
}

.accordion.accordion-modern-grey-scale-1 .card:first-of-type,
.accordion.accordion-modern-grey-scale-1 .card:not(:first-of-type):not(:last-of-type),
.accordion.accordion-modern-grey-scale-1 .card:last-of-type {
  border-color: #ededed;
}

.accordion.accordion-modern-grey-scale-1 .card .card-header {
  background-color: #ededed;
}


@media (max-width: 1250px) {
  .accordion {
    width: auto;
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .accordion-button::before, .accordion-button:not(.collapsed):before {
    width: 30px;
    height: 30px;
  }
}

