@font-face {
  font-family: "Metropolis Extra";
  src: url("./fonts/Metropolis-ExtraLight.woff2") format("woff2"),
    url("./fonts/Metropolis-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/Metropolis-ExtraBold.woff2") format("woff2"),
    url("./fonts/Metropolis-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/Metropolis-Bold.woff2") format("woff2"),
    url("./fonts/Metropolis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/Metropolis-Medium.woff2") format("woff2"),
    url("./fonts/Metropolis-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/Metropolis-Regular.woff2") format("woff2"),
    url("./fonts/Metropolis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/Metropolis-Light.woff2") format("woff2"),
    url("./fonts/Metropolis-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/Metropolis-Black.woff2") format("woff2"),
    url("./fonts/Metropolis-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Semi";
  src: url("./fonts/Metropolis-SemiBold.woff2") format("woff2"),
    url("./fonts/Metropolis-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("./fonts/Metropolis-Thin.woff2") format("woff2"),
    url("./fonts/Metropolis-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Metropolis";
}

.MM_26 {
  font-size: 26px;
  font-family: 'Metropolis-Medium';
}

.MM_para_22 {
  font-size: 22px;
  font-family: 'Metropolis-Medium';
}

.MM_para_16 {
  font-size: 16px;
  font-family: 'Metropolis-Medium';
}

.gensis-font {
  font-family: 'Genesys';
  font-size: 20px;
}

.Mb_bold_22 {
  font-size: 22px;
  font-family: 'Metropolis-Bold';
}

.Mb_bold_16 {
  font-size: 16px;
  font-family: 'Metropolis-Bold';
}

.MR-para-18 {
  font-size: 18px;
  font-family: 'Metropolis-Regular';
}

.MR-para-16 {
  font-size: 16px;
  font-family: 'Metropolis-Regular';
}

.M-Bold_80 {
  font-size: 80px;
  font-family: 'Metropolis-Bold';
  line-height: 80px;
}

.M-Bold_46 {
  font-size: 46px;
  font-family: 'Metropolis-Bold';
  margin-bottom: 1rem;
}

.M-Bold_60 {
  font-size: 60px;
  font-family: 'Metropolis-Bold';
  line-height: normal;
}

.M-Bold_20 {
  font-size: 20px;
  font-family: 'Metropolis-Bold';
}

.MR-reg-60 {
  font-size: 60px;
  font-family: 'Metropolis-Regular';
}


.MR-reg-60-1 {

  line-height: 1;
}

.MR-SB-28 {
  font-size: 28px;
  font-family: 'Metropolis Semi';
}

.MR-SB-16 {
  font-size: 16px;
  font-family: 'Metropolis Semi';
}

.MR-SB-18 {
  font-size: 18px;
  font-family: 'Metropolis Semi';
}

.ML-light-20 {
  font-family: 'Metropolis-Light';
  font-size: 20;
}

.ML-light-60 {
  font-family: 'Metropolis-Light';
  font-size: 60px;
}
