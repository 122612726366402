.padding_tb {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.text-primary, .p-color {
  color: var(--primary-color) !important;
}

.hd-color {
  color: var(--hd-color) !important;
}

.get-d {
  color: var(--primary-color);
  text-underline-offset: 3px;
}

.hd-wht {
  color: var(--white);
}

.img-auto {
  width: auto !important;
}

p {
  line-height: 26px;
  margin: 0 0 20px;
}

a,
a:hover,
a:focus {
  color: #ccc;
}

img {
  width: 100%;
}

.bg_color {
  background-color: var(--bg-color);
}

form-control {
  border-color: rgba(0, 0, 0, 0.09);
}

.form-control:not(.form-control-lg) {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 13.6px;
  font-size: 0.85rem;
  line-height: 1.85;
}

.form-control.form-control-focused {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-color: var(--primary-color) !important;
}

.form-control:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-color: var(--primary-color) !important;
}

.form-control.error {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control.error:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.form-select.form-select-icon-light {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.form_inp input::placeholder, .form_inp textarea::placeholder {
  font-size: 16px;
  font-family: 'Metropolis-Medium';
  color: rgba(0, 0, 0, 0.3);
}

.form_sidebar .row {
  flex-direction: column;
}

.gray-col {
  color: #a7a7a7;
}

@media (max-width: 1400px) {
  .M-Bold_80 {
    font-size: 66px;
    font-family: "Metropolis";
  }
  .M-Bold_46 {
    font-size: 38px;
  }
  .MR-reg-60 {
    font-size: 50px;
  }
  .M-Bold_60 {
    font-size: 50px;
    font-family: "Metropolis";
    font-weight: 500;
  }
  .MR-para-18 {
    font-size: 15px;
    font-family: "Metropolis-Regular";
  }
  .ML-light-60 {
    font-size: 50px;
  }
  .MM_para_22 {
    font-size: 18px;
  }
  .MM_para_21 {
    font-size: 16px;
  }
  .px-20 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
}

@media (max-width: 1250px) {
  .inr-footrer-grid {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
  }
  .navbar-brand {
    width: 180px;
  }
  .navbar-nav .nav-item .nav-link {
    font-size: 11px;
  }
  .hm-bg-black h3 {
    width: auto;
  }
}

@media (max-width: 1024px) {
  .fr_inr-line .inr-dp-line-gr {
    display: block;
  }
  .inr-footrer-grid {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 992px) {
  .navbar-nav .nav-item .nav-link {
    font-size: 12px;
  }
  .wpd {
    width: auto;
  }
  .ft-form .form_inp {
    width: auto;
  }
  .ftr-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
  }
  .two_grid,
  .grid_mtwo,
  .grid_rtwo,
  .grid_rtwo {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
  .wid img {
    border-radius: 40px;
  }
  .inr_grid h3 {
    margin-top: 2rem;
  }
  .form_inp {
    width: auto;
    text-align: center;
  }

  .f-logo-inr {
    text-align: left;
  }
  .padding_tb {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .MM_26 {
    font-size: 20px;
    font-family: "Metropolis-Medium";
  }
  .two_grids {
    column-gap: 2rem;
  }
  .navbar-collapse {
    background: #f1f1f1;
    padding: 1rem;
    border-radius: 0.9rem;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  }
  .nav_btn {
    margin-left: 0;
    margin-top: 0.8rem;
  }
  .navbar-light .navbar-toggler {
    color: transparent;
    border-color: transparent;
  }
}

@media (max-width: 768px) {
  .fr_inr-lines {
    margin-left: 0;
  }
  .M-Bold_80 {
    font-size: 54px;
    font-family: "Metropolis";
    font-weight: 500;
  }
  .MR-reg-60,
  .M-Bold_60,
  .ML-light-60 {
    font-size: 45px;
  }
  .ft-form {
    transform: translateY(0%);
  }
  .accordion-button::before {
    width: 56px;
    margin-right: 0.21rem;
  }
  .M-Bold_46 {
    font-size: 35px;
    margin-bottom: 1rem !important;
  }
  .padd-ft-tp {
    padding-top: 0rem !important;
  }
  .two_grids {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    display: flex;
    flex-direction: column-reverse;
  }
  .two_grido {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column-reverse;
  }
  .cont-add {
    margin-top: 2rem;
  }
  .gensis-font {
    font-family: "Genesys";
    font-size: 17px;
  }
}

@media (max-width: 592px) {
  .ftr-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .inr-footrer-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
  }
  .blk-bg {
    padding-left: 0.51rem;
    padding-right: 0.51rem;
  }
  .with-out-caroz {
    display: none;
  }
  .boot-carosel {
    display: block;
  }
  .sec-owl-caro .boot-carosel .carousel-item .inr-cr-bt .dp-inr-cr-bt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .img-f {
    width: 100px !important;
  }
  .img-s {
    width: 160px !important;
  }
  .sec-owl-caro .boot-carosel .carousel-indicators [data-bs-target] {
    background-color: var(--primary-color);
  }
  .sec-owl-caro .boot-carosel .carousel-indicators {
    bottom: -36px;
  }
  .f-logo-inr {
    text-align: center;
  }
  .form_inp {
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    padding: 2rem;
  }
  .contactpage .padd-ft-tp {
    padding-top: 3rem;
  }
}

@media (max-width: 470px) {
  .M-Bold_80 {
    font-size: 44px;
    text-align: left;
    font-weight: 500;
    line-height: 1.25;
  }
  .hd-color {
    text-align: left;
  }
  .MM_para_22 {
    font-size: 18px;
  }
  .MR-reg-60,
  .M-Bold_60,
  .ML-light-60 {
    font-size: 35px;
  }
  .M-Bold_46 {
    font-size: 26px;
  }
  .padding_tb {
    padding-top: 2.3rem;
    padding-bottom: 2.3rem;
  }
  .MR-SB-28 {
    font-size: 22px;
  }
  .MM_26 {
    font-size: 16px;
  }

  .ft-form .form_inp .inr_forms {
    display: block;
  }
  .inr-ftr-grid {
    margin-top: 1rem;
  }
  .navbar-brand {
    width: 200px;
  }
  .grid_mtwo {
    display: flex;
    flex-direction: column-reverse;
  }
  .padd-ft-tp {
    padding-top: 11rem;
    padding-bottom: 5rem;
  }
  .gensis-font {
    font-family: "Genesys";
    font-size: 14px;
    text-align: left;
  }
  .inr-ct-img {
    width: 65px !important;
    height: 65px;
  }
  .cont-inr-cont .cardo {
    grid-column-gap: 1rem;
  }
  .exerci .grid_mtwo {
    display: flex;
    flex-direction: column;
  }
  .dp-inr-footer-grid .subbtn {
    margin-bottom: 1rem;
  }
  .text_c {
    text-align: center;
  }
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Form Controls */
.form-select {
  height: 50px;
  font-size: 16px;
  font-family: 'Metropolis-Medium';
  outline: 0;
  box-shadow: none;
  color: #7e7e7e;
}

.form-select:focus {
  border-color: #dadada;
  outline: 0;
  box-shadow: none;
}

.form_inp {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 2.3rem;
  background: #fff;
}

.form_inp input {
  height: 50px;
  margin-bottom: 1rem;
}

.form_inp input::placeholder {
  font-size: 16px;
  font-family: 'Metropolis-Medium';
}

.form_sidebar.form_inp {
  box-shadow: none;
  padding: 0;
}

.inr_grid .form_inp {
  width: 100%;
}


/* Remove number input spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.form-control:focus {
  border-color: #dadada;
  outline: 0;
  box-shadow: none;
}

/* Utility Classes */
.text-orange-color {
  color: #ff6600;
}

.rounded-4-3 {
  border-radius: 1.3rem !important;
}

.d-opa {
  opacity: 0;
}

.paddingo {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

/* Lists */
.main_ul {
  padding-left: 0;
}

.main_ul li {
  list-style: none;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start;
}

.main_ul img {
  margin-right: 0.5rem;
}

.main_ul li img {
  width: auto;
}

/* Media Queries */
@media (max-width: 592px) {
  .mob-remove {
    display: none !important;
  }
}

@media (min-width: 592px) {
  .mob-remove-1 {
    display: none !important;
  }
}

.inr-ct-img {
  align-items: center;
  background-image: linear-gradient(90deg, #f60, #ff9147);
  border-radius: 5px;
  display: flex;
  height: 85px;
  justify-content: center;
  width: 85px !important;
}

.cont-inr-cont .card-contento {
  grid-area: content;
  margin-top: -54px;
}

.cont-inr-cont .cardo {
  grid-column-gap: 1.51rem;
}

.cont-email .card-contento {
  margin-top: -30px !important;
}

.cont-add .cardo {
  margin-bottom: 0;
}
