.nav_num {
    border: 1px solid var(--primary-color);
    display: flex;
}

.navbar-nav .nav-item {
    display: flex;
    align-items: center;
}

.nav_num a {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.nav_num {
    padding: 0.19rem 0.5rem;
    border-radius: 5px;
}

.nav_num img {
    width: 25px;
    margin-right: 0.51rem;
}

.nav_btn {
    background-image: linear-gradient(to right, #ff6600, #ff9147);
    margin-left: 1rem;
    display: flex;
    padding: 0.3rem 1rem;
    border-radius: 5px;
}

.nav_btn a {
    color: #fff !important;
    display: flex;
    align-items: center;
}
