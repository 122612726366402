
@media (max-width: 1250px) {
  .main-caro {
    justify-content: center;
  }
  .hm-bg-black h3 {
    width: auto;
  }
}

@media (max-width: 1024px) {
  /*home page css*/
  .hm-grid-thr {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 992px) {
  /*home page css */
  .banner_index {
    background: none !important;
  }
  .banner_index .two_grid {
    display: flex;
    flex-direction: column-reverse;
  }
  .d-g-m img {
    display: block !important;
  }
  .ser-blk .hm-grid-thr {
    margin-top: 2rem;
  }
}

.d-g-m img {
  display: none;
}

@media (max-width: 768px) {
  /*home page css*/
  .hm-grid-thr {
    grid-column-gap: 3rem !important;
  }
  .owl-carousel .item {
    padding: 0.9rem;
  }
}

@media (max-width: 470px) {
  /*home page css start*/
  .hm-grid-thr {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .banner_index .inr_grid {
    text-align: center;
  }
}

.banner_index {
  background: url(../../images/v3.png);
  background-position: 75% 55px;
  background-size: 73%;
  background-repeat: no-repeat;
}

textarea.form-control {
  min-height: calc(5.5em + (1.75rem + 20px));
}

.c-bg {
  /* background: url(./Assets/images/c-v.png); */
  background-position: center;
  background-size: cover;
  background-position: 107%;
  background-size: 30%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.bold-text {
  font-weight: bold;
}

.bg-black {
  background-color: black !important;
}

.b-white {
  border: 1px solid white;
}

.p-3 {
  padding: 3px;
}

.mt-7 {
  margin-top: 3rem;
}

.c-pointer,
.c-pointer:hover {
  cursor: pointer;
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Metropolis-Bold' !important;
}

.text-orange-color {
  color: #ff6600;
}

.text-gray-color {
  color: #3f3f3f !important;
}

.fas {
  color: #ff6600;
}

.options {
  color: #797979 !important;
  font-size: 14px !important;
  font-family: var(--bs-font-sans-serif) !important;
}

.gray-placeholder::placeholder {
  color: #797979 !important;
  font-size: 14px;
  font-family: var(--bs-font-sans-serif) !important;
}

.custom-shadow {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.butn-orange-color {
  background-color: #ff9751;
  background-image: linear-gradient(to right, #ff6600, #ff9751);
  border-color: #ff6600 #ff7c22 #ff9751;
  color: white;
}

.orange-check {
  width: 15px !important;
}

.test {
  position: absolute;
  width: 3px;
  height: 71px;
  background: var(--primary-color);
  left: -22px;
  top: 5px;
}

.main-line {
  position: relative;
}

.inner {
  position: absolute;
  width: 3px;
  height: 6.5%;
  background: #ff6600;
  top: 0px;
}

@media (max-width: 500px) {
  .m-mobile {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .inner {
    top: 2.5%;
    height: 5%;
  }

  .live-chat-div {
    width: auto;
  }

  .w-25-div {
    width: auto !important;
  }

  .blog-img {
    width: 100% !important;
  }
}

@media (min-width: 501px) {
  .extra-link {
    display: none !important;
  }
}

ul li {
  list-style: none;
}

.logo-footer {
  width: 260px;
  display: none;
}

.w-25-div {
  width: 25%;
}

.dropdown-menu {
  margin-top: 4rem;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 10px;
  left: 100%;
  /* margin-top: 10px; */
}

.hm-grid-thr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 6rem;
  margin-top: 4rem;
}

.inr-hm-grid-thr {
  position: relative;
  margin-top: 1rem;
}

.inr-dp-line-gr {
  position: absolute;
  width: 3px;
  height: 22px;
  background: var(--primary-color);
  left: -22px;
  top: 5px;
}

.inr-hm-grid-thr:before {
  content: '';
  display: block;
  width: 1px;
  height: 150px;
  background: #3c3b3b;
  position: absolute;
  left: -21px;
  top: 5px;
}

.fr_inr-lines {
  margin-left: 1rem;
}

.main_appointment {
  background-size: cover;
  background: url(../../images/bg1.png) repeat center;
  position: relative;
}

.btn-apt {
  border: 1px solid #fff;
  background: transparent;
  padding: 1.2rem 2rem 0.9rem;
}
