@font-face {
  font-family: "Metropolis Extra";
  src: url("Assets/css/fonts/Metropolis-ExtraLight.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Extra";
  src: url("Assets/css/fonts/Metropolis-ExtraBold.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis-Bold";
  src: url("Assets/css/fonts/Metropolis-Bold.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Assets/css/fonts/Metropolis-Medium.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Assets/css/fonts/Metropolis-Regular.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Assets/css/fonts/Metropolis-Light.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Assets/css/fonts/Metropolis-Black.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Semi";
  src: url("Assets/css/fonts/Metropolis-SemiBold.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("Assets/css/fonts/Metropolis-Thin.woff2") format("woff2"),
    url("Assets/css/fonts/Metropolis-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Metropolis-Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */


