:root {
  --primary-color: #ff6600;
  --secondary: #ff9147;
  --bg-color: #f5f5f5;
  --hd-color: #3f3f3f;
  --white: #fff;
  --footer: #000;
  --background: #f8f8f8;
  --small_hd: #5d5d5d;
  --tab_bg: #eaeaea;
  --arrow_leftright: #bdbdbd;
  --btn_bg: #f0f0f0;
  --talk_bg: #102048;
  --spantb: #d1d1d1;
  --form_bg: #aa020d;
  --footer: #000;
  --copyright: #203362;
  --main_blue: #21325e;
  --input_bg: #eae5e5;
  --cont_input: #5d5d5d;
  --blog_tabs_bg: #ffe1e3;
}

