.footer {
  z-index: 100;
}


.inr-ftr-ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  column-gap: 4rem;
}

.inr-ftr-ul ul {
  padding-left: 0;
}

.inr-ftr-ul ul li {
  color: #a7a7a7;
  list-style: none;
  margin-bottom: 0.5rem;
  font-size: 15px;
}

.inr-ftr-ul ul a {
  text-decoration: none;
}
